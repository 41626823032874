'use strict'

jQuery(function ($) {
  $('button.menu').click(function () {
    if ($('nav.site-nav').hasClass('expanded')) {
      $('nav.expanded').removeClass('expanded').hide(250)
      $(this).removeClass('open')
      $(this).find('span').text('Menu')
      $(this).siblings('.menu-overlay').removeClass('show')
      $(this).closest('body').removeClass('noscroll')
    } else {
      $('nav.site-nav').addClass('expanded').show(250)
      $(this).addClass('open')
      $(this).find('span').text('Close')
      $(this).siblings('.menu-overlay').addClass('show')
      $(this).closest('body').addClass('noscroll')
    }
  })
});
